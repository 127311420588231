
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block7',
  mixins: [cmsBlock],

  data () {
    return {
      active: 0,
      fallbackContent: {
        title: 'Discover new content',
        tabs: [
          {
            title: 'Article',
            items: [
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://img.pikbest.com/photo/20240609/beautiful-lady-doctor-from-uk-_10585583.jpg!w700wp',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
            ],
          },
          {
            title: 'Trainings',
            items: [
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },
            ],
          },
          {
            title: 'Interviews',
            items: [
              {
                title: 'Physical activity for Seniors',
                subtitle: 'dr. Kasia Kowalsa',
                image: 'https://img.redro.pl/fototapety/pani-doktor-700-67334604.jpg',
                to: '/url',
              },
              {
                title: 'Dental caries',
                subtitle: 'dr. Ewa Kowalsa',
                image: 'https://as2.ftcdn.net/v2/jpg/01/03/48/57/500_F_103485758_UPWYEscFWadN7UqGzLx4Cz34bdD8J8Ee.jpg',
                to: '/url',
              },

            ],
          },
        ],
      },
    }
  },

  watch: {
    active () {
      this.$nextTick(() => {
        this.$refs.carousel?.scrollToStart?.()
      })
    },
  },

  methods: {
    goTo (url) {
      if (url.startsWith('http')) {
        window.open(url, '_blank')
      } else {
        this.$router.push(url)
      }
    },
  },
}
