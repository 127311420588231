import { render, staticRenderFns } from "./CmsMediclub3Block8.vue?vue&type=template&id=394cc7f4&scoped=true&"
import script from "./CmsMediclub3Block8.vue?vue&type=script&lang=js&"
export * from "./CmsMediclub3Block8.vue?vue&type=script&lang=js&"
import style0 from "./CmsMediclub3Block8.vue?vue&type=style&index=0&id=394cc7f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394cc7f4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsMediclub3Heading: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v3/CmsMediclub3Heading.vue').default,BaseImage: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,CmsMediclub3Auth: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v3/CmsMediclub3Auth.vue').default})
